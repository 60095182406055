import * as React from "react"
import Layout from "../components/common/layout"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import ListVideo from "../components/list-videos"
// import Hero from "../components/hero"
import Heading from "../components/common/heading"
import PreFooter from "../components/pre-footer"
import PartnerBanner from "../components/partner-banner"
import SubMenu from "../components/common/sub-menu"
import Pagination from 'react-bootstrap/Pagination'
// markup 
const VideoHubCategory = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const videos = _data.find((item: any) => item.block === 'videos')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
//   const hero = _data.find((item: any) => item.block === 'hero')

  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataPartnerBanner = _data.find((item: any) => item.block === 'partnerBanner')
  return (
    <Layout>
      <Helmet
        title='Video main category'
      />
      <SubMenu data={subMenu.data[0]} />
      <div className="video-container">
        <div className="filter-block">
            <div className="container">
                <div className="filter">
                    <button className="btn btn-outline-secondary me-3 active">All</button>
                    <button className="btn btn-outline-secondary me-3 ">Beginner</button>
                    <button className="btn btn-outline-secondary me-3 ">Intermediate</button>
                    <button className="btn btn-outline-secondary me-3 ">Advanced</button>
                </div>
            </div>
        </div>
        <ListVideo data={videos.data[0]} />
        <div className="container pagination-container pt-4">
            <Pagination className="d-none d-md-flex">
                {/* <Pagination.First /> */}
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Item>{6}</Pagination.Item>
                <Pagination.Item>{7}</Pagination.Item>
                <Pagination.Item>{8}</Pagination.Item>
                <Pagination.Item>{9}</Pagination.Item>
                <Pagination.Next />
                {/* <Pagination.Last /> */}
              </Pagination>
              <Pagination className="d-flex d-md-none">
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Next />
              </Pagination>
        </div>
      </div>
      <PartnerBanner data={dataPartnerBanner.data[0]} background="bg-green" ></PartnerBanner>
      <PreFooter data={dataPrefooter.data[0]} background="bg-blue"></PreFooter>  
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["video-sub-category"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
           
            partner_banner {
              content
              link {
                id
                text
                url
              }
              logo
              partner_image
            }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
              }
            } 
            sub_menu{
              title
              links {
                id
                text
                link
                active
              }
            }
            videos {
              id
              title
              link
              image
              video {
                length
                level
                name
                tag
              }
            }
          }
        }
      }
    }
  }
`
export default VideoHubCategory

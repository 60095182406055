import * as React from 'react'
import { graphql,Link } from 'gatsby'
import Block from './common/block'
const PartnerBanner = (props: any) => {
    const data = props.data.partner_banner
    const className = props.className ?  props.className : ''
    const background = props.background ?  props.background : ''
  return (
    <>
        <Block className={'partner-banner-block p-0 mb-40 mt-40'  + className }   >
            <div className='container'>
                <div className={ 'partner-banner-block__content d-flex justify-content-between ' + background } >
                    <div className="partner-banner-block__left d-flex justify-content-between align-items-center">
                        <div className="corporate-logos d-flex justify-content-between">
                            <div className="llga-brand">
                                <img src={data.logo} />
                            </div>
                            <div className='corporate-logos__plus'>
                                <i className='icon-plus'></i>
                            </div>
                          
                            <div className="partner-brand">
                                <img src={data.partner_image} />
                            </div>
                        </div>
                        <div className="partner-slogan h4 mb-0">{data.content}</div>
                    </div>
                    <div className="buttons partner-banner-block__right d-flex justify-content-between align-items-center">
                        <Link className="btn btn-primary btn-lg mt-0" to={data.link.url}>{data.link.text}</Link>
                    </div>
                </div>
            </div> 
        </Block> 
    </>
  )
}

export default PartnerBanner

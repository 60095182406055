import * as React from 'react'
import Slider from 'react-slick';
import styled from 'styled-components'
import { breakpointLg, breakpointMd} from '../design/responsive/breakpoint'
import CardVideo from './common/card-video';

const Block = styled.section`
`;
const ListVideo = (props: any) => {
    const Videos = props.data.videos;
    // const vertical = props.vertical ? 'col-lg-12': 'col-lg-6';
    const settings = {
        peed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        rows: 1,
        autoplay: false,
        dots: false,
        cssEase: 'linear',
        autoplaySpeed: 5000, 
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            }
        ]
    };
    return (
        <>
            <Block className='list-videos'>
                <div className='container'>
                {
                    Videos.length ? (
                    <div className='row'>
                        {
                        Videos.map((video: any)=>(
                            <div className='col-md-3' key={video.id}>
                                    <CardVideo data={video} /> 
                            </div>
                        ))
                        }
                    </div>
                    ) : null
                }
                </div>
            </Block>
        </>
    )
}

export default ListVideo


import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import CardImage from './card-image'
import { breakpointMd} from '../../design/responsive/breakpoint'
const Card = styled.div`
    height: 100%;
    flex-direction: column;
    padding-bottom: 4rem;
    .ratio img{
        border-radius: 1.25rem;
    }
    @media (max-width: ${breakpointMd}) {
        padding-bottom: 2rem;
      }
}`
const CardBody = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 1.5rem;
}`

const CardTitle = styled.h4`
   flex:1;
   margin-bottom: 1rem;
}`
const CardVideo = (props: any) => {
  const ratio = props.ratio ? props.ratio : '3x2'
  const data = props.data
  const video = props.data.video
  const className = props.className ?  props.className : ''

  return (
    <>
        <Card className={className + ' card card-video'}>
            <Link to={data.link} className='text-decoration-none'><CardImage src={data.image} alt='' ratio="16x9" /></Link>
            <CardBody className='card-body p-0'>
                <CardTitle className='f-16'>
                    <Link to={data.link} className='text-decoration-none'>{data.title}</Link>
                </CardTitle>
                <div className='row video-info f-14 text-muted'>
                    <div className='video-info-line col-md-6 col'>
                        <div className='name'>{video.name}</div>
                        <div className='tag'>{video.tag}</div>
                    </div>
                    <div className='video-info-line col-md-6 col'>
                        <div className='length text-align-right'>{video.length}</div>
                        <div className='level text-align-right'>{video.level}</div>
                    </div>
                </div>
            </CardBody>
        </Card>
    </>
  )
}

export default CardVideo
